import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section contact">
          <h4>SKAI Technology</h4>
          <p>Phnom Penh, Cambodia</p>
          <p>contact@skaitechnology.com</p>
        </div>
        <div className="footer-section social-media">
          <h4>Social Media</h4>
          <p><a href="https://web.facebook.com/skaitechnology.company/" target="_blank" rel="noopener noreferrer">Facebook</a></p>
        </div>
      </div>
      <div className="footer-bottom">
        © 2024 SKAI Technology Co, Ltd. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
