import React, { useState } from 'react';
import './Contact.css'; // Ensure to create and link Contact.css
import Section from '../Section/Section.jsx'; // Adjust path as necessary

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    content: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you'd typically integrate an email service or backend to send the data
    console.log(formData);
    alert('Your message has been sent!');
  };

  return (
    <div className="contact-container">
      <h1 className="contact-title">Get in Touch</h1>
      <form className="contact-form" onSubmit={handleSubmit}>
        <input type="text" name="firstName" placeholder="First Name" required onChange={handleChange} />
        <input type="text" name="lastName" placeholder="Last Name" required onChange={handleChange} />
        <input type="text" name="company" placeholder="Company" onChange={handleChange} />
        <input type="email" name="email" placeholder="Email" required onChange={handleChange} />
        <textarea name="content" placeholder="Your Message" required onChange={handleChange} />
        <button type="submit" className="submit-button">Send Message</button>
      </form>
    </div>
  );
};

export default Contact;
