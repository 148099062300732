import React from 'react';
import Section from '../Section/Section.jsx'; // Assuming Section is in the same directory
import './OurServices.css'; // Make sure to create and link the corresponding CSS file

const ServiceCard = ({ title, subtitle, content, icon }) => {
    const contentItems = content.split(', ');

    return (
        <div className="service-card">
            <img src={`${icon}`} alt={`${title} icon`} className="service-icon"/>
            <h3 className="service-title">{title}</h3>
            <ul className="service-content">
                {contentItems.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        </div>
    );
};

const OurServices = () => {
  const services = [
    {
      title: "Consulting",
      subtitle: "KNOWLEDGE",
      content: "Strategy Consulting, Digital Transformation, Estimations & Feasibility",
      icon: "consulting.png"
    },
    {
      title: "Digital Services",
      subtitle: "PRODUCTS",
      content: "Web / Mobile Apps, Automation Solutions, AI-Powered Integrations",
      icon: "digital.png"
    },
    {
      title: "Staff Augmentation",
      subtitle: "RESOURCES",
      content: "Designers, Developers, IT Specialists",
      icon: "staff.png"
    },
  ];

  return (
    <Section title="Our Services" titleColor="var(--prussian-blue)" backgroundColor="var(--light-prussian-blue)">
      {services.map((service, index) => (
        <ServiceCard
          key={index}
          title={service.title}
          subtitle={service.subtitle}
          content={service.content}
          icon={service.icon}
        />
      ))}
    </Section>
  );
};

export default OurServices;
