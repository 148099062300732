import React from 'react';
import './HeroSection.css'; // Link to the corresponding CSS file
import Section from '../Section/Section.jsx'; // Adjust path as necessary
import SkillsWheel from '../SkillsWheel/SkillsWheel.jsx'

const HeroSection = () => {
    return (
      <Section title="" titleColor="var(--prussian-blue)" backgroundColor="var(--light-prussian-blue)">
        <div className="hero-section">
          <h2 className="hero-title">Skyrocket Your Business</h2>
          <p className="hero-statement">We aligns technology with your business goals.<br />Partner with us to boost your operations and growth.</p>
        </div>
        <SkillsWheel />
        
      </Section>
    );
  };
  
export default HeroSection;
