import React, { useState } from 'react';
import './FAQ.css'; // Ensure to create and link FAQ.css
import Section from '../Section/Section.jsx'; // Adjust path as necessary

// Example FAQ data
const faqs = [
  {
    question: 'What services do you offer?',
    answer: 'We offer a wide range of services includingWe offer a wide range of services including web development, AI solutions, and more.We offer a wide range of services including web development, AI solutions, and more.We offer a wide range of services including web development, AI solutions, and more.We offer a wide range of services including web development, AI solutions, and more.We offer a wide range of services including web development, AI solutions, and more.We offer a wide range of services including web development, AI solutions, and more.We offer a wide range of services including web development, AI solutions, and more.We offer a wide range of services including web development, AI solutions, and more. web development, AI solutions, and more.',
  },
  {
    question: 'How can I contact support?',
    answer: 'You can contact our support team via email at support@skaitechnology.com.',
  },
  {
    question: 'What services do you offer?',
    answer: 'We offer a wide range of services including web development, AI solutions, and more.',
  },
  {
    question: 'How can I contact support?',
    answer: 'You can contact our support team via email at support@skaitechnology.com.',
  },
  {
    question: 'What services do you offer?',
    answer: 'We offer a wide range of services including web development, AI solutions, and more.',
  },
  {
    question: 'How can I contact support?',
    answer: 'You can contact our support team via email at support@skaitechnology.com.',
  },
  {
    question: 'What services do you offer?',
    answer: 'We offer a wide range of services including web development, AI solutions, and more.',
  },
  {
    question: 'How can I contact support?',
    answer: 'You can contact our support team via email at support@skaitechnology.com.',
  },
  {
    question: 'What services do you offer?',
    answer: 'We offer a wide range of services including web development, AI solutions, and more.',
  },
  {
    question: 'How can I contact support?',
    answer: 'You can contact our support team via email at support@skaitechnology.com.',
  },
  // Add more FAQs here
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <Section title="Frequently Asked Questions" titleColor="var(--prussian-blue)" backgroundColor="var(--white)">
    <div className="faq-container">
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}>
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              {faq.question}
            </div>
            <div className="faq-answer">
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
    </Section>
  );
};

export default FAQ;
