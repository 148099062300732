import React from 'react';
import { useParams } from 'react-router-dom';

const articles = [
    {
      id: 1,
      title: 'The Future of AI',
      content: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.',
        'https://picsum.photos/300/200?id=1',
        'Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi. Proin porttitor, orci nec nonummy molestie, enim est eleifend mi, non fermentum diam nisl sit amet erat.',
        'https://picsum.photos/300/200?id=2',
        'Duis semper. Duis arcu massa, scelerisque vitae, consequat in, pretium a, enim. Pellentesque congue. Ut in risus volutpat libero pharetra tempor.',
        'https://picsum.photos/300/200?id=3',
      ],
      path: '/blog/the-future-of-ai'
    },
    {
      id: 2,
      title: 'Understanding React',
      content: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.',
        'https://picsum.photos/300/200?id=1',
        'Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa.',
        'https://picsum.photos/300/200?id=2',
        'Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero.',
        'https://picsum.photos/300/200?id=3'
      ],
      path: '/blog/understanding-react'
    }
  ];

  const ArticleDetail = () => {
    const { articleId } = useParams();
    const article = articles.find(article => `/blog/${articleId}` === article.path);
  
    if (!article) {
      return <div>Article not found.</div>;
    }
  
    return (
      <div className="article-detail-container">
        <h1 className="article-title">{article.title}</h1>
        {article.content.map((item, index) =>
          item.startsWith('http') ? <img key={index} src={item} alt="Dynamic" className="article-image" /> : <p key={index} className="article-content">{item}</p>
        )}
      </div>
    );
  };
  
  export default ArticleDetail;
