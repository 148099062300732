import React, { useState, useEffect } from 'react';
import './SkillsWheel.css';

const titles = [
    'React', 'Python', 'Wordpress',
    'Vue JS', 'PHP', 'iOS', 'Android', 'AI', "Automation"
];

const SkillsWheel = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(prevIndex => (prevIndex + 1) % titles.length);
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="slider">
            {titles.map((title, index) => {
                const totalVisible = 7;  // Number of visible titles
                const middleIndex = Math.floor(totalVisible / 2); // Index of the middle title
                const positionIndex = (index - activeIndex + titles.length) % titles.length;
                const distanceFromMiddle = Math.abs(positionIndex - middleIndex);
                
                let opacity;
                switch (distanceFromMiddle) {
                    case 0:
                        opacity = 1;   // Middle title
                        break;
                    case 1:
                        opacity = 0.5; // One step from the middle
                        break;
                    case 2:
                        opacity = 0.25; // Two steps from the middle
                        break;
                    case 3:
                        opacity = 0.125; // Three steps from the middle or more
                        break;
                    default:
                        opacity = 0; // Three steps from the middle or more
                        break;
                }

                return (
                    <div
                        key={index}
                        className="slider-item"
                        style={{
                            transform: `translateY(${positionIndex * 100}%)`,
                            opacity: opacity
                        }}
                    >
                        {title}
                    </div>
                );
            })}
        </div>
    );
};

export default SkillsWheel;
