import React from 'react';
import './OurValues.css'; // Link to the corresponding CSS file
import Section from '../Section/Section.jsx'; // Adjust path as necessary

const OurValues = () => {
  return (
    <Section title="Our Values" titleColor="var(--prussian-blue)" backgroundColor="var(--white)">
      <div className="values-container">
        {values.map((value, index) => (
          <div key={index} className="value-card">
            <img src={`${value.icon}`} alt={`${value.title} Icon`} className="value-icon" />
            <h3 className="value-title">{value.title}</h3>
            <p>{value.description}</p>
          </div>
        ))}
      </div>
    </Section>
  );
};

const values = [
    {
      title: "Purpose",
      description: "Deliver top-quality digital services at prices everyone can afford.",
      icon: 'vision.png'
    },
    {
      title: "Ambition",
      description: "Set the standard for accessible, high-quality technology solutions.",
      icon: 'ambition.png'
    },
    {
      title: "Mission",
      description: "Guide our clients to be customer-focused, adaptable, and excel sustainably.",
      icon: 'mission.png'
    }
  ];

export default OurValues;
