import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header.jsx';
import Footer from './components/Footer/Footer.jsx';
import HeroSection from './components/HeroSection/HeroSection.jsx';
import OurValues from './components/OurValues/OurValues.jsx';
import OurStrengths from './components/OurStrengths/OurStrengths.jsx';
import OurServices from './components/OurServices/OurServices.jsx';
import Methodology from './components/Methodology/Methodology.jsx';
import Portfolio from './components/Portfolio/Portfolio.jsx';
import Contact from './components/Contact/Contact.jsx';
import Blog from './components/Blog/Blog.jsx';
import ArticleDetail from './components/ArticleDetail/ArticleDetail.jsx';
import FAQ from './components/FAQ/FAQ.jsx';
import './assets/theme.css';


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <HeroSection />
            <OurValues />
            <OurServices />
            <OurStrengths />
          </>
        } />
        <Route path="/methodology" element={<Methodology />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:articleId" element={<ArticleDetail />} />
        <Route path="/faq" element={<FAQ />} />

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
