import React from 'react';
import { Link } from 'react-router-dom';
import './Blog.css'; // Ensure to create and link Blog.css

const articles = [
  {
    id: 1,
    title: 'The Future of AI',
    summary: 'Exploring the advancements in AI technology...',
    path: '/blog/the-future-of-ai',
    url: 'https://picsum.photos/300/200?random=1'
  },
  {
    id: 2,
    title: 'Understanding React',
    summary: 'A deep dive into the world of React development...',
    path: '/blog/understanding-react',
    url: 'https://picsum.photos/300/200?random=2'
  },
  // ... other articles
];

const Blog = () => {
  return (
    <div className="blog-container">
      <h1 className="blog-title">Our Blog</h1>
      {articles.map(article => (
        <div key={article.id} className="article-preview">
          <Link to={article.path} className="article-link">
            <h2>{article.title}</h2>
            <img src={article.url} alt={article.title} className="article-image" />
            <p>{article.summary}</p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Blog;
