import React from 'react';
import './Portfolio.css'; // Make sure to link to Portfolio.css

const projects = [
  { id: 1, title: 'Facebook', url: 'https://www.facebook.com', description: 'Social networking service connecting people worldwide.' },
  { id: 2, title: 'Google', url: 'https://www.google.com', description: 'The world\'s premier search engine.' },
  { id: 3, title: 'Amazon', url: 'https://www.amazon.com', description: 'E-commerce pioneer with a vast product selection.' },
  { id: 4, title: 'Netflix', url: 'https://www.netflix.com', description: 'Streaming service offering a wide variety of award-winning TV shows, movies, and more.' },
  { id: 5, title: 'Twitter', url: 'https://www.twitter.com', description: 'Microblogging platform for real-time news and updates.' },
  { id: 6, title: 'Spotify', url: 'https://www.spotify.com', description: 'Digital music service giving access to millions of songs.' },
];

const Portfolio = () => {
  return (
    <div className="portfolio">
      <h1 className="portfolio-title">Our Projects</h1>
      <div className="projects-container">
        {projects.map(project => (
          <a key={project.id} href={project.url} target="_blank" rel="noopener noreferrer" className="project-card">
            <img src={`https://picsum.photos/400/300?random=${project.id}`} alt={project.title} />
            <div className="project-info">
              <h3>{project.title}</h3>
              <p>{project.description}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default Portfolio;
