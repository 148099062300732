import React from 'react';
import Section from './../Section/Section.jsx';
import './OurStrengths.css';

const ReasonCard = ({ title, content, logo }) => {
  return (
    <div className="reason-card">
      <img src={`${logo}`} alt={`${title} logo`} className="reason-icon"/>
      <h3 className="reason-title">{title}</h3>
      <p className="reason-content">{content}</p>
    </div>
  );
};

const OurStengths = () => {
  const reasons = [
    {
      title: "Technical Expertise",
      content: "Our engineers are graduates from top universities across Asia and Europe.",
      logo: "expertise.png"
    },
    {
      title: "Flexibility",
      content: "We dynamically adjust resources and efforts to meet your goals and budget.",
      logo: "flexibility.png"
    },
    {
      title: "Quality and Reliability",
      content: "We choose projects we're passionate about to ensure top-quality results and success.",
      logo: "quality.png"
    }
  ];

  return (
    <Section title="Why Us?" backgroundColor="white" titleColor="var(--prussian-blue)">
      <div className="reasons-container">
        {reasons.map((reason, index) => (
          <ReasonCard key={index} title={reason.title} content={reason.content} logo={reason.logo} />
        ))}
      </div>
    </Section>
  );
};

export default OurStengths;
