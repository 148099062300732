import React from 'react';
import './Methodology.css'; // Ensure this CSS file is linked correctly

const Methodology = () => {
  const steps = [
    { title: 'Idea Generation', description: 'We start with your vision, pinpointing the ultimate goal and identifying your target audience.', icon: 'flexibility.png' },
    { title: 'Research & Analysis', description: 'Our team dives into the market landscape, collecting data to inform the scope and direction of your project.', icon: 'flexibility.png' },
    { title: 'Design & Planning', description: 'Ideas take shape through collaborative design sessions, plotting out key features and the project’s technical feasibility.', icon: 'flexibility.png' },
    { title: 'Strategy Development', description: 'We articulate a clear vision statement, paired with a strategic roadmap to guide the development process.', icon: 'flexibility.png' },
    { title: 'User Insights', description: 'Through in-depth user research, we craft profiles that capture the essence of your end-users’ needs and behaviors.', icon: 'flexibility.png' },
    { title: 'Iterative Refinement', description: 'Engaging with stakeholders, we refine our plans, ensuring alignment with business objectives and user expectations.', icon: 'flexibility.png' },
    { title: 'Product Validation', description: 'We continuously validate our approach, ensuring alignment with user needs, and prepare for a phased release strategy.', icon: 'flexibility.png' },
    { title: 'Development Phases', description: 'From prototype to MVP to Version 1, we develop and enhance the product based on continuous user feedback.', icon: 'flexibility.png' },
  ];

  return (
    <div className="methodology">
      <h1 className="methodology-title">Our Methodology</h1>
      {steps.map((step, index) => (
        <div key={index} className="step-card">
          <div className="step-info">
            <img src={step.icon} alt="" className="step-icon" />
            <div>
              <h2 className="step-title">{step.title}</h2>
              <p>{step.description}</p>
            </div>
          </div>
          <div className="step-number">{index + 1}</div>
        </div>
      ))}
    </div>
  );
};

export default Methodology;