import React, { useState } from 'react';
import './Header.css';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="header">
            <a href ="/"> 
                <div className="logo-and-title">
                <img src="/skai-technology.png" alt="SKAI Technology logo" className="logo" />
                    <h1 className="site-title">SKAI Technology</h1>
                </div>
            </a>
            <nav className="desktop-nav">
                {/* <a href="/" className="nav-link">ABOUT US</a> */}
                {/* <a href="/methodology" className="nav-link">METHODOLOGY</a>
                <a href="/portfolio" className="nav-link">PORTFOLIO</a>
                <a href="/blog" className="nav-link">BLOG</a>
                <a href="/faq" className="nav-link">FAQ</a> */}
            </nav>
            {/* <a href ="/contact"> <button className="contact-button">CONTACT US</button></a> */}
            <a href="mailto:contact@skaitechnology.com?subject=Contact%20from%20Website">
                <button className="contact-button">CONTACT US</button>
            </a>
            {/* <button className="menu-button" onClick={toggleMenu}>☰</button> */}
            {isMenuOpen && (
                <div className="menu-popup">
                    <button onClick={toggleMenu} className="menu-close">✕</button>
                    <ul className="menu-list">
                        {/* <li><a href="/">ABOUT US</a></li> */}
                        {/* <li><a href="/methodology">METHODOLOGY</a></li>
                        <li><a href="/portfolio">PORTFOLIO</a></li>
                        <li><a href="/blog">BLOG</a></li>
                        <li><a href="/FAQ">FAQ</a></li> */}
                    </ul>
                </div>
            )}
        </header>
    );
};

export default Header;
