import React from 'react';
import './Section.css'; // Link to the Section CSS file

const Section = ({ children, title, titleColor, backgroundColor }) => {
  return (
    <div className="section" style={{ backgroundColor }}>
      {title && <h1 style={{ color: titleColor }} className="section-title">{title}</h1>}
      <div className="cards-container">{children}</div>
    </div>
  );
};

export default Section;